import { Has, getComponentValueStrict, defineEnterSystem } from "@latticexyz/recs";
import { EntityTypes } from "../../../../Network/types";
import { Tileset } from "../../constants";
import { PhaserLayer } from "../../types";

const entityTypeToTile = {
  [EntityTypes.Grass]: Tileset.Grass,
  [EntityTypes.Mountain]: Tileset.Rock1,
  [EntityTypes.River]: Tileset.Water,
} as { [key in EntityTypes]: Tileset };

/**
 * The Map system handles rendering the phaser tilemap
 */
export function createMapSystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Position, EntityType },
      },
    },
    scenes: {
      Main: {
        maps: { Main, Pixel, Tactic, Strategic },
        camera,
        objectPool,
      },
    },
  } = layer;

  const zoomSub = camera.zoom$.subscribe((zoom) => {
    if (zoom < 0.1) {
      Strategic.setVisible(true);
      Tactic.setVisible(false);
      Pixel.setVisible(false);
      Main.setVisible(false);
      camera.ignore(objectPool, true);
    } else if (zoom < 0.5) {
      Strategic.setVisible(false);
      Tactic.setVisible(true);
      Pixel.setVisible(true);
      Main.setVisible(false);
      camera.ignore(objectPool, true);
    } else {
      Strategic.setVisible(false);
      Tactic.setVisible(false);
      Pixel.setVisible(false);
      Main.setVisible(true);
      camera.ignore(objectPool, false);
    }
  });
  world.registerDisposer(() => zoomSub?.unsubscribe());

  defineEnterSystem(world, [Has(Position), Has(EntityType)], (update) => {
    const coord = getComponentValueStrict(Position, update.entity);
    const type = getComponentValueStrict(EntityType, update.entity);
    const tile = entityTypeToTile[type.value as EntityTypes];

    Main.putTileAt(coord, tile);

    // compute cluster for LOD
    if (coord.x % 4 === 0 && coord.y % 4 === 0) {
      const tacticCoord = { x: Math.floor(coord.x / 4), y: Math.floor(coord.y / 4) };
      Tactic.putTileAt(tacticCoord, tile);
    }

    if (coord.x % 16 === 0 && coord.y % 16 === 0) {
      const strategicCoord = { x: Math.floor(coord.x / 16), y: Math.floor(coord.y / 16) };
      Strategic.putTileAt(strategicCoord, tile);
    }
  });
}
